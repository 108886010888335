export default {
  isJPG: function (filePathOrFile) {
    try {
      let fileExtension;

      if (filePathOrFile instanceof File) {
        // Extract file extension from the File object
        fileExtension = filePathOrFile.name.split(".").pop();
      } else {
        // Handle string paths or URLs
        fileExtension = filePathOrFile.split(".").pop().split("?")[0];
      }

      
      return fileExtension === "jpg";
    } catch (error) {
      
      return false;
    }
  },
};

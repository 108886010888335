import React, { useEffect, useState } from "react";
import Button from "../ui/Button";
import { useNavigate } from "react-router-dom";
import ImagePreviewer from "../ui/image-preview/ImagePreviewer";
import { ToastContainer } from "react-toastify";
import firebase from "../lib/firebase";

export default function NavBar({ pageTitle, isHome = false }) {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  return (
    <>
      <ToastContainer />
      <div
        className={` fixed w-full transition-all duration-1000  z-50
                ${
                  scrollPosition > 40
                    ? " bg-gradient-to-b from-black md:via-[#000000b8] md:pb-[.75rem]"
                    : `${
                        isHome
                          ? "_bg-transparent bg-gradient-to-b from-[#0F1219] max-md:bg-transparent"
                          : ""
                      }`
                }`}
      >
        <NavBarContent pageTitle={pageTitle} scrollPosition={scrollPosition} />
      </div>
      <div className="h-[7rem]"></div>
    </>
  );
}

export function NavBarContent({ pageTitle, scrollPosition }) {
  const navigate = useNavigate();
  const menuItems = [
    {
      display: "HOME",
      link: "/",
    },
    {
      display: "ABOUT US",
      link: "/aboutus",
    },
    {
      display: "WEDDING",
      link: "/wedding",
    },
    {
      display: "CORPORATES",
      link: "/corporates",
    },
    {
      display: "SERVICES",
      link: "/services",
    },
    {
      display: "PROCESS & FABRICS",
      link: "/processandfabrics",
    },
    {
      display: "LOOK BOOK",
      link: "/lookbook",
    },
    {
      display: "BLOG",
      link: "/blog",
      hiddenInDesktop: true,
    },
    {
      display: "FAQ",
      link: "/faq",
      hiddenInDesktop: true,
    },
    {
      display: "CONTACT US",
      link: "/contactus",
    },
  ];
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <FloatingIcons />

      <div
        className={`relative z-20 flex justify-between items-center m-auto px-2 pt-10 text-white container transition-all duration-300  ${
          scrollPosition > 100 ? "pt-6 pb-2" : ""
        }`}
      >
        <div
          className="cursor-pointer LOGO"
          onClick={() => {
            navigate("/");
          }}
        >
          <img
            className="w-[105px] max-md:w-[75px] max-md:ml-[10px]"
            src="/logo.png"
            alt=""
          />
        </div>
        <h2 className="md:hidden -ml-4 font-bold serif">{pageTitle}</h2>
        <button
          className="z-40 lg:hidden mr-3 p-2 menubutton"
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        >
          <img
            className={`${
              showMenu
                ? "fixed right-3 top-3 rotate-180   w-[17px] transition-all duration-300  "
                : ""
            }  right-0 top-0 rotate-180 z-[999] relative  `}
            src="/icons/menu.svg"
            alt=""
          />
        </button>
        <ul
          className={
            "flex _gap-4 font-normal text-[13.69px] leading-tight MENU flex-nowrap whitespace-nowrap transition-all duration-500 ease-in-out overflow-hidden  " +
            `${
              showMenu
                ? "max-lg:h-screen rounded-[0]"
                : "max-lg:h-0 max-lg:w-full overflow-hidden _rounded-full"
            } max-lg:fixed top-0 right-0 max-lg:w-screen  max-lg:bg-black max-lg:flex-col max-lg:items-center max-lg:justify-center`
          }
        >
          {menuItems.map((item, index) => {
            return (
              <li
                key={item.link + index.toString()}
                onClick={() => {
                  if (window.location.pathname != item.link)
                    navigate(item.link, {
                      state: { fromNav: true },
                      viewTransition: true,
                    });
                }}
                className={`p-2 ${
                  window.location.pathname == item.link ||
                  (window.location.pathname.includes(item.link) &&
                    window.location.pathname.includes("blog"))
                    ? "text-white font-semibold "
                    : "hover:text-gray-400 "
                }
                ${item.hiddenInDesktop ? "lg:hidden" : ""}
                cursor-pointer`}
              >
                {item.display}
              </li>
            );
          })}
        </ul>
        <div className="max-xl:hidden _bg-white w-[57px] h-5"></div>
        {/* <Button
          className={"max-xl:hidden"}
          onClick={() => navigate("/contactus")}
        >
          BOOK A CONSULTATION
        </Button> */}
      </div>
      <ImagePreviewer />
    </>
  );
}

function FloatingIcons() {
  return (
    <div className="relative top-[calc(100dvh-5rem)] right-0 left-auto w-full h-0 transition-all z-40">
      <div className="relative bg-green-500 mr-5 ml-auto p-3 rounded-full w-max">
        {/* <div className="absolute bg-green-700 rounded-full w-full h-full animate-pulse"></div> */}
        <a href="https://wa.me/971504554707?text=Hi,%20I%20am%20connecting%20through%20your%20website.">
          <img className="w-[30px]" src="/icons/whatsapp_icon.svg" alt="" />
        </a>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import Button from "../ui/Button";
import { useNavigate } from "react-router-dom";
import { addSubscription } from "../lib/firebase";
import {
  Facebook,
  FacebookOutlined,
  FacebookSharp,
  Instagram,
} from "@mui/icons-material";

export default function Footer() {
  const menuItems = [
    // {
    //   display: "ABOUT US",
    //   link: "/aboutus",
    // },
    {
      display: "WEDDING",
      link: "/wedding",
    },
    {
      display: "CORPORATES",
      link: "/corporates",
    },
    {
      display: "SERVICES",
      link: "/services",
    },
    {
      display: "PROCESS & FABRICS",
      link: "/processandfabrics",
    },
    {
      display: "LOOK BOOK",
      link: "/lookbook",
    },
    {
      display: "BLOG",
      link: "/blog",
    },
    // {
    //   display: "CONTACT US",
    //   link: "/contactus",
    // },
    {
      display: "FAQ",
      link: "/faq",
    },
  ];

  const [sendButtonText, setSendButtonText] = useState("SUBSCRIBE");
  const [messageSendStatusMessage, setMessageSendStatusMessage] = useState({
    statusMessage: "",
    success: true,
  });

  const navigate = useNavigate();
  return (
    <footer className="bg-[#171920] pb-[2rem] pt-[4rem] text-white">
      <img className="lg:hidden m-auto w-[100px]" src="/logo.png" alt="" />
      <div className="items-center gap-1 max-lg:hidden grid grid-cols-[3rem_6rem_auto] max-lg:grid-cols-1 LINE">
        <div className="bg-white w-full h-[.5px]"></div>
        <div>
          <img
            className="max-lg:hidden -mt-[1.9rem] w-[5rem] mx-auto"
            src="/logo.png"
            alt=""
          />
        </div>
        <div className="max-lg:hidden bg-white w-full h-[.5px]"></div>
      </div>
      <div className="max-lg:block grid grid-cols-[1fr_min-content_1fr] m-auto px-[2rem] pt-[2.5rem] max-lg:pt-0 h-full container">
        <div className="max-lg:block grid grid-cols-2 p-1 LEFT">
          <div className="max-lg:m-auto max-lg:py-[1.75rem] max-lg:max-w-xl max-lg:text-center ABOUT">
            <h1 className="pb-4 text-[14.03px] text-lg max-lg:hidden">SANTOBA TAILORS</h1>
            <p className="max-lg:m-auto max-md:max-w-[calc(100vw-4rem)] lg:max-w-[220px] max-md:font-extralight text-[14.03px]">
              Founded in Dubai’s Meena Bazaar by two visionary brothers, Santoba
              Tailors has grown into a trusted name in luxury tailoring. Our
              journey began with a commitment to precision, personalized
              service, and a refined approach to style.{" "}
            </p>
          </div>
          <ul className="flex flex-col gap-3 max-lg:hidden pl-5 text-gray-100 text-sm MENU">
            {menuItems.map((item, index) => {
              return (
                <li
                  key={item.link + index.toString()}
                  className={
                    "w-min font-light text-[12.69px] hover:text-white hover:underline whitespace-nowrap cursor-pointer "
                  }
                  onClick={() => {
                    navigate(item.link);
                  }}
                >
                  {item.display}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="max-lg:hidden bg-[#333333] w-[2px] h-full LINE"></div>
        <div className="max-lg:hidden grid grid-cols-[auto_auto] RIGHT">
          <div className="w-20"></div>
          <div className="max-w-[400px]">
            <h2 className="pb-2 font-light text-[12.38px]">
              SUBSCRIBE UPDATES
            </h2>
            <div className=" items-center bg-[#B7B7B7] w-full max-w-[25rem] grid grid-flow-col grid-cols-[auto_min-content]">
              <input
                id="subscription-email"
                className="bg-[#B7B7B7] focus:bg-[#d3d2d2] p-1 pl-3 w-full h-[2rem] text-black placeholder:text-[13px] placeholder:text-black outline-none"
                placeholder="ENTER YOUR EMAIL ADDRESS"
                type="text"
              />

              <Button
                className={"w-max text-nowrap"}
                onClick={() => {
                  const email =
                    window.document.getElementById("subscription-email").value;
                  addSubscription(email).then(() => {
                    window.document.getElementById("subscription-email").value = ''
                    setMessageSendStatusMessage({
                      statusMessage: "Subscribed successfully!",
                      success: true,
                    });
                    setTimeout(() => {
                      setMessageSendStatusMessage({
                        statusMessage: "",
                        success: true,
                      });
                    }, 1000);
                  });
                }}
              >
                {sendButtonText}
              </Button>
            </div>

            {messageSendStatusMessage.statusMessage ? (
              <div
                className={`col-span-2  mt-1 p-1 w-full font-bold text-[12px] text-center uppercase  ${
                  messageSendStatusMessage.success
                    ? "_bg-green-800 text-white"
                    : "_bg-red-800 text-white"
                }`}
              >
                {messageSendStatusMessage.statusMessage}
              </div>
            ) : <div className="h-[30px]"></div>}
            <div className="pt-3 CONNECT">
              <h2 className="pb-2 font-light text-[10.38px]">CONNECT</h2>
              <div className="flex gap-3">
                <a href="https://www.instagram.com/santobatailors/">
                  <Instagram />
                  {/* <img className="h-[16px]" src="/icons/instagram.svg" alt="" /> */}
                </a>
                <a href="https://www.facebook.com/SantobaTailorsCo">
                  <FacebookSharp />
                  {/* <img className="h-[16px]" src="/icons/web.svg" alt="" /> */}
                </a>
              </div>
              <Button
                className={"mt-5"}
                onClick={() => {
                  navigate("/contactus", { viewTransition: true });
                }}
              >
                {" "}
                BOOK A CONSULTATION
              </Button>
            </div>
          </div>
        </div>
      </div>
      <p className="max-lg:text-[10px] mt-10 font-normal text-[#929292] text-[9.38px] text-center text-xs sans-serif">
        COPYRIGHT &#169; 2024 | SANTOBA TAILORS. ALL RIGHTS RESERVED
      </p>
    </footer>
  );
}

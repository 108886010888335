import React, { useEffect, useState } from "react";
import AdminLayout from "./AdminLayout";
import {
  addLookbook,
  deleteLookbook,
  getLookbook,
  uploadFile,
} from "../../lib/firebase";
import Button from "../../ui/Button";
import { toast } from "react-toastify";
import themedAlert from "../../lib/alert";

export default function LookbookManager() {
  const [lookbooks, setLookbooks] = useState([{ altText: "", imageUrl: "" }]);
  const [submitionIndTemp, setSubmitionIndTemp] = useState(0);
  const [addLookbookOpen, setAddLookbookOpen] = useState(false);
  useEffect(() => {
    getLookbook().then((res) => {
      setLookbooks(res);
    });
  }, [submitionIndTemp]);

  return (
    <AdminLayout>
      <NewLookbook
        setSelfOpen={setAddLookbookOpen}
        isSelfOpen={addLookbook}
        className={`${
          addLookbookOpen
            ? "bg-[#00000087] backdrop-blur-sm fixed h-full z-10 grid items-center overflow-hidden w-[calc(100%-15rem)] transition-opacity justify-center max-md:w-full max-md:items-end max-md:grid-cols-1 opacity-100 "
            : "opacity-0 h-0 pointer-events-none hidden"
        }`}
        onSubmit={() => {
          setSubmitionIndTemp(submitionIndTemp + 1);
        }}
      />
      <section className="px-2 md:px-[3rem]">
        <div className="grid grid-cols-[auto_min-content] mt-[2rem]">
          <div></div>
          <div className="flex gap-3 BUTTONS">
            <button
              className={
                "bg-accent text-white hover:bg-blue-950 transition-all rounded-md w-min whitespace-nowrap px-[26px] py-[.5rem] text-[12.07px] font-normal"
              }
              onClick={() => {
                setAddLookbookOpen(true);
              }}
            >
              Add lookbook image
            </button>

            {/* <Button className={'bg-blue-600 text-white hover:bg-blue-900 transition-all rounded-md w-min whitespace-nowrap'}>Edit</Button> */}
          </div>
        </div>

        <LookBookImages
          lookbooks={lookbooks}
          reload={() => {
            setSubmitionIndTemp(submitionIndTemp + 1);
          }}
        />
      </section>
    </AdminLayout>
  );
}

function LookBookImages({ lookbooks, reload }) {
  return (
    <section className="mt-[2rem]">
      <div className="justify-center gap-5 grid grid-cols-4 max-md:grid-cols-2 max-lg:grid-cols-3 overflow-hidden aspect-auto IMAGES">
        {lookbooks?.map((lookbookItem, index) => {
          return (
            <div
              className="relative rounded-md overflow-hidden border-2 hover:border-accent transition-all border-gray-500"
              key={lookbookItem.id + index.toString()}
            >
              <div className="bottom-0 left-0 absolute items-end content-center gap-2 grid grid-cols-[auto_min-content] bg-gradient-to-t from-black p-3 w-full">
                <p className="p-[2px] font-bold text-sm text-white">
                  {lookbookItem.category || "NA"}
                </p>
                <button
                  className={
                    "bg-red-600 text-white hover:bg-red-900 transition-all rounded-md w-min whitespace-nowrap  px-[10px] py-[.5rem] text-[12.07px] font-normal"
                  }
                  onClick={() => {
                    deleteLookbook(lookbookItem.id, lookbookItem.imageUrl).then(
                      () => {
                        reload();
                      }
                    );
                  }}
                >
                  Delete
                </button>
              </div>

              <img
                data-preview="false"
                className="flex-[300px] grow-0 w-full h-[15rem] aspect-[4/5] object-cover "
                key={lookbookItem.imageUrl + index}
                src={lookbookItem.imageUrl}
                alt={lookbookItem.altText}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
}

function NewLookbook({ onSubmit, className, isSelfOpen, setSelfOpen }) {
  const [image, setImage] = useState(null);
  const [altText, setAlt] = useState("");
  const [category, setCategory] = useState("CASUAL");
  const selectableCategories = [
    "CASUAL",
    "SUMMER",
    "CORPORATE",
    "WEDDING",
    "WOMEN",
  ];

  return (
    <div
      className={className}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          setSelfOpen(false);
        }
      }}
    >
      <div
        className={
          "animate-zoom-up gap-3 grid grid-cols-1 max-w-sm max-md:grid-cols-1 max-lg:grid-cols-2 bg-white md:mt-5 p-10 md:border border-b border-accent md:rounded-lg max-md:max-w-full "
        }
      >
        <input
          className="p-1 border border-accent"
          type="file"
          accept="image/png, image/jpg, image/jpeg"
          onChange={(e) => {
            if (e.target.files?.[0].size >= 350 * 1024) {
              themedAlert(
                "File should be less than 350 kb",
                "red"
              );
              return;
            }
            setImage(e.target.files[0]);
          }}
        />
        {/* <input
          className="p-1 border border-accent"
          type="text"
          onChange={(e) => {
            setAlt(e.target.value);
          }}
        /> */}
        <select
          className="p-1 border border-accent"
          onChange={(e) => {
            setCategory(e.target.value);
          }}
        >
          {selectableCategories.map((item, index) => {
            return <option  key={index} >{item}</option>;
          })}
        </select>
        <button
          className="bg-accent text-white p-1 border"
          onClick={async () => {
            const imageUrl = await uploadFile(image, "lookbook/");
            await addLookbook({ imageUrl, altText, category }).then(() => {
              onSubmit();
              setAlt("");
            });
          }}
        >
          Publish
        </button>
      </div>
    </div>
  );
}

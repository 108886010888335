import React, { useEffect, useState } from "react";
import AdminLayout from "./AdminLayout";
import Button from "../../ui/Button";
import {
  getData,
  saveData,
  uploadImageFromFileOrLocalUrl,
} from "../../lib/firebase";
import themedAlert from "../../lib/alert";
import helper from "../../lib/helper";

export default function ImageManager() {
  return (
    <AdminLayout>
      <section className="relative p-10">
        <HomeImages />
        <AboutImages />
        <WeddingImages />
        <CorporateImages />
        <ServicesImages />
      </section>
    </AdminLayout>
  );
}

const fileFromElement = (elementSelector) => {
  const element = document.querySelector(elementSelector);
  if (
    element &&
    element.tagName.toLowerCase() === "input" &&
    element.type === "file" &&
    element.files[0]
  ) {
    return element.files[0]; // Access files directly from the input element
  }
  return null;
};
export const defaultHomeImages = {
  head: "/home/head.jpg",
  mission: "/home/mission.jpg",
  vision: "/home/vision.jpg",
  fourLookbookImages: [
    "/home/av1.jpg",
    "/home/av2.jpg",
    "/home/av3.jpg",
    "/home/av4.jpg",
  ],
  eightInstaImages: [
    "/home/s1.jpg",
    "/home/s2.jpg",
    "/home/s3.jpg",
    "/home/s4.jpg",
    "/home/s5.jpg",
    "/home/s6.jpg",
    "/home/s7.jpg",
    "/home/s8.jpg",
  ],
};

export const defaulHomeImagesFromFirebase = {
  head: firebaseStorageMediaAddress(`home-head.jpg`),
  mission: firebaseStorageMediaAddress(`home-mission.jpg`),
  vision: firebaseStorageMediaAddress(`home-vision.jpg`),
  fourLookbookImages: [
    firebaseStorageMediaAddress(`home-lookbook-0.jpg`),
    firebaseStorageMediaAddress(`home-lookbook-1.jpg`),
    firebaseStorageMediaAddress(`home-lookbook-2.jpg`),
    firebaseStorageMediaAddress(`home-lookbook-3.jpg`),
  ],
  eightInstaImages: [
    firebaseStorageMediaAddress(`home-insta-0.jpg`),
    firebaseStorageMediaAddress(`home-insta-1.jpg`),
    firebaseStorageMediaAddress(`home-insta-2.jpg`),
    firebaseStorageMediaAddress(`home-insta-3.jpg`),
    firebaseStorageMediaAddress(`home-insta-4.jpg`),
    firebaseStorageMediaAddress(`home-insta-5.jpg`),
    firebaseStorageMediaAddress(`home-insta-6.jpg`),
    firebaseStorageMediaAddress(`home-insta-7.jpg`),
  ],
};
export function firebaseStorageMediaAddress(fileName, trigger = 0) {
  if (window.location.pathname.includes("admin"))
    return `https://firebasestorage.googleapis.com/v0/b/santoba-website.firebasestorage.app/o/website-images%2F${fileName}?alt=media&time=${Date.now()}`;
  else
    return `https://firebasestorage.googleapis.com/v0/b/santoba-website.firebasestorage.app/o/website-images%2F${fileName}?alt=media&time=${new Date().toLocaleDateString()}`;
}
const HomeImages = () => {
  const [updateImagesTrigger, setUpdateImagesTrigger] = useState(0);

  const [homeImages, setHomeImages] = useState(defaulHomeImagesFromFirebase);
  const [progress, setProgress] = useState(0);

  async function saveHomeImagesToStateAndGetData() {
    const homeImagesFromDom = {
      head:
        fileFromElement(".home-head") ||
        firebaseStorageMediaAddress(`home-head.jpg`),
      mission:
        fileFromElement(".home-mission") ||
        firebaseStorageMediaAddress(`home-mission.jpg`),
      vision:
        fileFromElement(".home-vision") ||
        firebaseStorageMediaAddress(`home-vision.jpg`),
      fourLookbookImages: [],
      eightInstaImages: [],
    };
    for (let index = 0; index < 4; index++) {
      homeImagesFromDom.fourLookbookImages.push(
        fileFromElement(`.home-lookbook-${index}`) ||
          firebaseStorageMediaAddress(`home-lookbook-${index}.jpg`)
      );
    }
    for (let index = 0; index < 8; index++) {
      homeImagesFromDom.eightInstaImages.push(
        fileFromElement(`.home-insta-${index}`) ||
          firebaseStorageMediaAddress(`home-insta-${index}.jpg`)
      );
    }
    
    // setHomeImages(homeImagesFromDom);
    return homeImagesFromDom;
  }

  async function saveToStorage(_homeImages) {
    setProgress(10);
    _homeImages?.head &&
      (await uploadImageFromFileOrLocalUrl(
        _homeImages?.head,
        "/website-images/home-head.jpg"
      ).then(() => setProgress(20)));
    _homeImages?.mission &&
      (await uploadImageFromFileOrLocalUrl(
        _homeImages?.mission,
        "/website-images/home-mission.jpg"
      ).then(() => setProgress(30)));
    _homeImages?.vision &&
      (await uploadImageFromFileOrLocalUrl(
        _homeImages?.vision,
        "/website-images/home-vision.jpg"
      ).then(() => setProgress(40)));
    await Promise.all(
      _homeImages?.fourLookbookImages.map(async (item, index) => {
        item &&
          (await uploadImageFromFileOrLocalUrl(
            item,
            `/website-images/home-lookbook-${index}.jpg`
          ).then(() => setProgress(70)));
      })
    );
    await Promise.all(
      _homeImages?.eightInstaImages.map(async (item, index) => {
        item &&
          (await uploadImageFromFileOrLocalUrl(
            item,
            `/website-images/home-insta-${index}.jpg`
          ).then(() => setProgress(100)));
      })
    );
    setProgress(0);
  }

  return (
    <div className="relative HOME">
      <div className="top-0 z-20 sticky grid grid-cols-[auto_max-content] bg-white pt-5">
        <div>
          <div className="grid grid-cols-[auto_max-content] pb-2">
            <h1 className="font-bold text-xl">Home</h1>
            <div className="flex gap-2 buttons">
              <Button
                className={"border border-gray-400"}
                onClick={() => {
                  
                  saveHomeImagesToStateAndGetData().then(() => {
                    setHomeImages(defaultHomeImages);
                    saveToStorage(defaultHomeImages)
                      .then(() => {
                        setProgress(0);
                        themedAlert("Images reset to default successfully!");
                      })
                      .then(() => {
                        setUpdateImagesTrigger(updateImagesTrigger + 1);
                      });
                  });
                }}
              >
                Reset images to default
              </Button>
              <Button
                className={"border border-gray-400"}
                onClick={() => {
                  saveHomeImagesToStateAndGetData().then(
                    (imagesDataFromDom) => {
                      
                      setHomeImages(defaulHomeImagesFromFirebase);
                      saveToStorage(imagesDataFromDom).then(() => {
                        setProgress(0);
                        themedAlert("Images saved successfully!");
                      });
                    }
                  );
                }}
              >
                Save images
              </Button>
            </div>
          </div>
          <div
            className="items-center grid bg-gradient-to-br from-[#616c8a] to-[#3b455f] h-[20px] text-white text-xs transition-all overflow-hidden"
            style={{ width: `${progress}%` }}
          >
            <span className="px-3">Saving...</span>
          </div>
        </div>
        <div></div>
      </div>
      <div className="mt-5">
        <h2>Header image</h2>
        <ImageChangableContainer
          filePickerClassName="home-head"
          onlyJpgAllowed={true}
          key="home-head"
          isAdmin={true}
          imgClassName={"w-full h-full object-cover object-center home-head"}
          containerClassName={
            "m-auto max-h-[600px] border border-gray-400 overflow-hidden aspect-video w-full"
          }
          image={homeImages?.head}
        />
        <div className="gap-2 grid grid-cols-2">
          <div className="mt-5">
            <h2>Mission image</h2>
            <ImageChangableContainer
              filePickerClassName="home-mission"
              onlyJpgAllowed={true}
              isAdmin={true}
              imgClassName={
                "w-full h-full object-cover object-center home-mission"
              }
              containerClassName={
                "border border-gray-400 overflow-hidden aspect-video"
              }
              image={homeImages?.mission}
            />
          </div>
          <div className="mt-5">
            <h2>Vision image</h2>
            <ImageChangableContainer
              filePickerClassName="home-vision"
              onlyJpgAllowed={true}
              isAdmin={true}
              imgClassName={
                "w-full h-full object-cover object-center home-vision"
              }
              containerClassName={
                "border border-gray-400 overflow-hidden aspect-video"
              }
              image={homeImages?.vision}
            />
          </div>
        </div>

        {/* Lookbook Images */}
        <div>
          <h2 className="mt-5">Lookbook images in home</h2>
          <div className="gap-2 grid grid-cols-4">
            {homeImages?.fourLookbookImages.map((item, index) => (
              <div key={index}>
                <ImageChangableContainer
                  filePickerClassName={`home-lookbook-${index}`}
                  onlyJpgAllowed={true}
                  isAdmin={true}
                  imgClassName={`w-full h-full object-cover object-center home-lookbook-${index}`}
                  containerClassName={
                    "border border-gray-400 overflow-hidden w-full h-full aspect-[2/3]"
                  }
                  image={item}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Insta Images */}
        <div>
          <h2 className="mt-5">Square images in home</h2>
          <div className="gap-2 grid grid-cols-4">
            {homeImages?.eightInstaImages.map((item, index) => (
              <div key={index}>
                <ImageChangableContainer
                  filePickerClassName={`home-insta-${index}`}
                  onlyJpgAllowed={true}
                  isAdmin={true}
                  imgClassName={`w-full h-full object-cover object-center home-insta-${index}`}
                  containerClassName={
                    "border border-gray-400 overflow-hidden w-full h-full aspect-square"
                  }
                  image={item}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const defaultAboutUsImage = "/aboutus.jpg";

export const defaultAboutUsFirebasePath =
  firebaseStorageMediaAddress("aboutus.jpg");
function AboutImages() {
  const [aboutUsImage, setAboutUsImage] = useState(defaultAboutUsFirebasePath);
  const [progress, setProgress] = useState(0);

  async function saveAboutUsImageToStateAndGetData() {
    const aboutusImageFromDom =
      fileFromElement(".aboutus") ||
      firebaseStorageMediaAddress("aboutus.jpg", Date.now());

    return aboutusImageFromDom;
  }

  async function saveToStorage(_aboutUsImage) {
    if (_aboutUsImage) {
      await uploadImageFromFileOrLocalUrl(
        _aboutUsImage,
        "website-images/aboutus.jpg"
      ).then(() => setProgress(90));
    }
    setProgress(0);
  }

  return (
    <div className="relative mt-10 ABOUT">
      <div className="top-0 z-20 sticky grid grid-cols-[auto_max-content] bg-white pt-5">
        <div>
          <div className="grid grid-cols-[auto_max-content]">
            <h1 className="font-bold text-xl">About Us</h1>
            <div className="flex gap-2 pb-2 buttons">
              <Button
                className={"border border-gray-400"}
                onClick={() => {
                  
                  saveAboutUsImageToStateAndGetData().then(() => {
                    // saveData("website-images", "home", defaultHomeImages);
                    setAboutUsImage(defaultAboutUsImage);
                    setProgress(90);
                    saveToStorage(defaultAboutUsImage).then(() => {
                      setProgress(0);
                      themedAlert("Images reset to default successfully!");
                      setAboutUsImage(
                        firebaseStorageMediaAddress("aboutus.jpg", Date.now())
                      );
                    });
                  });
                }}
              >
                Reset images to default
              </Button>
              <Button
                className={"border border-gray-400"}
                onClick={() => {
                  saveAboutUsImageToStateAndGetData().then(
                    (imagesDataFromDom) => {
                      saveToStorage(imagesDataFromDom).then(() => {
                        setProgress(0);
                        themedAlert("Images saved successfully!");
                      });
                    }
                  );
                }}
              >
                Save images
              </Button>
            </div>
          </div>
          <div
            className="items-center grid bg-gradient-to-br from-[#616c8a] to-[#3b455f] h-[20px] text-white text-xs transition-all overflow-hidden"
            style={{ width: `${progress}%` }}
          >            <span className="px-3">Saving...</span>
          </div>
        </div>
        <div></div>
      </div>
      <div className="_mt-1">
        <ImageChangableContainer
          filePickerClassName={"aboutus"}
          isAdmin={true}
          imgClassName={"max-h-[600px] aboutus"}
          containerClassName={"_m-auto border border-gray-400 w-fit"}
          image={aboutUsImage}
        />
      </div>
    </div>
  );
}

const defaultWeddingImages = [
  "/wedding/1.jpg",
  "/wedding/2.jpg",
  "/wedding/3.jpg",
];

export const defaultWeddingImagesFromFirebase = [
  firebaseStorageMediaAddress(`wedding-0.jpg`),
  firebaseStorageMediaAddress(`wedding-1.jpg`),
  firebaseStorageMediaAddress(`wedding-2.jpg`),
];
function WeddingImages() {
  const [weddingImages, setWeddingImages] = useState(
    defaultWeddingImagesFromFirebase
  );
  const [progress, setProgress] = useState(0);

  async function saveWeddingImagesToStateAndGetData() {
    const weddingImagesFromDom = [];
    for (let index = 0; index < 3; index++) {
      weddingImagesFromDom.push(fileFromElement(`.wedding-image-${index}`)) ||
        firebaseStorageMediaAddress(`wedding-${index}.jpg`);
    }
    
    return weddingImagesFromDom;
  }

  async function save() {
    setProgress(10);
    saveWeddingImagesToStateAndGetData().then((imagesDataFromDom) => {
      setProgress(90);
      Promise.all(
        imagesDataFromDom?.map(async (item, index) => {
          
          item &&
            (await uploadImageFromFileOrLocalUrl(
              item,
              `/website-images/wedding-${index}.jpg`
            ));
        })
      ).then(() => {
        setProgress(0);
        themedAlert("Saved successfully!");
      });
    });
  }

  function reset() {
    setWeddingImages(defaultWeddingImages);
    setProgress(90);
    Promise.all(
      defaultWeddingImages?.map(async (item, index) => {
        
        item &&
          (await uploadImageFromFileOrLocalUrl(
            item,
            `/website-images/wedding-${index}.jpg`
          ));
      })
    ).then(() => {
      setProgress(0);
      themedAlert("Images reset to default successfully!");
      setWeddingImages(defaultWeddingImagesFromFirebase);
    });
  }

  return (
    <div className="relative mt-10 WEDDING">
      <div className="top-0 z-20 sticky grid grid-cols-[auto_max-content] bg-white py-5">
        <div>
          <div className="grid grid-cols-[auto_max-content]">
            <h1 className="font-bold text-xl">Wedding</h1>
            <div className="flex gap-2 buttons">
              <Button className={"border border-gray-400"} onClick={reset}>
                Reset images to default
              </Button>
              <Button className={"border border-gray-400"} onClick={save}>
                Save images
              </Button>
            </div>
            <div
            className="items-center grid bg-gradient-to-br from-[#616c8a] to-[#3b455f] h-[20px] text-white text-xs transition-all overflow-hidden"
            style={{ width: `${progress}%` }}
          >
              <span className="px-3">Saving...</span>
            </div>
          </div>
        </div>

        <div></div>
      </div>
      <div>
        <div className="gap-2 grid grid-cols-3">
          {weddingImages.map((item, index) => (
            <div key={index}>
              <ImageChangableContainer
                filePickerClassName={`wedding-image-${index}`}
                isAdmin={true}
                imgClassName={`w-full h-full object-cover object-center wedding-image-${index}`}
                containerClassName={
                  "border border-gray-400 overflow-hidden aspect-video"
                }
                image={item}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const defaultCorporateImages = [
  "/corporates/1.jpg",
  "/corporates/2.jpg",
  "/corporates/3.jpg",
];

export const defaultCorporateImagesFromFirebase = [
  firebaseStorageMediaAddress(`corporate-0.jpg`),
  firebaseStorageMediaAddress(`corporate-1.jpg`),
  firebaseStorageMediaAddress(`corporate-2.jpg`),
];
function CorporateImages() {
  const [corporateImages, setCorporateImages] = useState(
    defaultCorporateImagesFromFirebase
  );
  const [progress, setProgress] = useState(0);

  async function saveCorporateImagesToStateAndGetData() {
    const corporateImagesFromDom = [];
    for (let index = 0; index < 3; index++) {
      corporateImagesFromDom.push(
        fileFromElement(`.corporate-image-${index}`) ||
          firebaseStorageMediaAddress(`corporate-${index}.jpg`)
      );
    }
    
    return corporateImagesFromDom;
  }

  async function save() {
    setProgress(10);
    saveCorporateImagesToStateAndGetData().then((imagesDataFromDom) => {
      setProgress(90);
      Promise.all(
        imagesDataFromDom.map(async (item, index) => {
          if (item) {
            await uploadImageFromFileOrLocalUrl(
              item,
              `/website-images/corporate-${index}.jpg`
            );
          }
        })
      ).then(() => {
        setProgress(0);
        themedAlert("Images saved successfully!");
      });
    });
  }

  function reset() {
    setCorporateImages(defaultCorporateImages);
    setProgress(90);
    Promise.all(
      defaultCorporateImages.map(async (item, index) => {
        if (item) {
          await uploadImageFromFileOrLocalUrl(
            item,
            `/website-images/corporate-${index}.jpg`
          );
        }
      })
    ).then(() => {
      setCorporateImages(defaultCorporateImagesFromFirebase);
      setProgress(0);
      themedAlert("Images reset to default successfully!");
    });
  }

  return (
    <div className="relative mt-10 CORPORATE">
      <div className="top-0 z-20 sticky grid grid-cols-[auto_max-content] bg-white py-5">
        <div>
          <div className="grid grid-cols-[auto_max-content]">
            <h1 className="font-bold text-xl">Corporate</h1>
            <div className="flex gap-2 buttons">
              <Button className="border-gray-400 border" onClick={reset}>
                Reset images to default
              </Button>
              <Button className="border-gray-400 border" onClick={save}>
                Save images
              </Button>
            </div>
            <div
            className="items-center grid bg-gradient-to-br from-[#616c8a] to-[#3b455f] h-[20px] text-white text-xs transition-all overflow-hidden"
            style={{ width: `${progress}%` }}
          >
              <span className="px-3">Saving...</span>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div>
        <div className="gap-2 grid grid-cols-3">
          {corporateImages.map((item, index) => (
            <div key={index}>
              <ImageChangableContainer
                filePickerClassName={`corporate-image-${index}`}
                isAdmin={true}
                imgClassName={`w-full h-full object-cover object-center corporate-image-${index}`}
                containerClassName="border border-gray-400 overflow-hidden aspect-video"
                image={item}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const defaultServiceImages = {
  suitsNtuxedos: {
    image: "/services/suits.jpg",
    display: "Suits & Tuxedos",
  },
  shirtsNtrousers: {
    image: "/services/shirt.jpg",
    display: "Shirts & Trousers",
  },
  jackets: {
    image: "/services/jacket.jpg",
    display: "Jackets",
  },
  alteration: {
    image: "/services/restyling.jpg",
    display: "Alterations & Restyling",
  },
  consultation: {
    image: "/services/consultation.jpg",
    display: "Personal Consultations",
  },
  shoes: {
    image: "/services/shoes.jpg",
    display: "Handmade Shoes",
  },
  accessories: {
    image: "/services/accessories.jpg",
    display: "Accessories",
  },
};

export const defaultServiceImagesFromFirebase = {
  suitsNtuxedos: {
    image: firebaseStorageMediaAddress(`service-suitsNtuxedos.jpg`),
    display: "Suits & Tuxedos",
  },
  shirtsNtrousers: {
    image: firebaseStorageMediaAddress(`service-shirtsNtrousers.jpg`),
    display: "Shirts & Trousers",
  },
  jackets: {
    image: firebaseStorageMediaAddress(`service-jackets.jpg`),
    display: "Jackets",
  },
  alteration: {
    image: firebaseStorageMediaAddress(`service-alteration.jpg`),
    display: "Alterations & Restyling",
  },
  consultation: {
    image: firebaseStorageMediaAddress(`service-consultation.jpg`),
    display: "Personal Consultations",
  },
  shoes: {
    image: firebaseStorageMediaAddress(`service-shoes.jpg`),
    display: "Handmade Shoes",
  },
  accessories: {
    image: firebaseStorageMediaAddress(`service-accessories.jpg`),
    display: "Accessories",
  },
};

function ServicesImages() {
  const [serviceImages, setServiceImages] = useState(
    defaultServiceImagesFromFirebase
  );
  const [progress, setProgress] = useState(0);

  async function saveServiceImagesToStateAndGetData() {
    const serviceImagesFromDom = {};
    Object.keys(defaultServiceImages).forEach((key) => {
      serviceImagesFromDom[key] = {
        image: fileFromElement(`.${key}`) || defaultServiceImages[key].image,
        display: defaultServiceImages[key].display,
      };
    });
    return serviceImagesFromDom;
  }

  async function save() {
    setProgress(100);
    saveServiceImagesToStateAndGetData().then((imagesDataFromDom) => {
      Promise.all(
        Object.entries(imagesDataFromDom).map(async ([key, service]) => {
          if (service.image instanceof File) {
            await uploadImageFromFileOrLocalUrl(
              service.image,
              `/website-images/service-${key}.jpg`
            );
          }
        })
      ).then(() => {
        setProgress(0);
        themedAlert("Images saved successfully!");
      });
    });
  }

  function reset() {
    setServiceImages(defaultServiceImages);
    Promise.all(
      Object.entries(defaultServiceImages).map(async ([key, service]) => {
        if (service.image) {
          await uploadImageFromFileOrLocalUrl(
            service.image,
            `/website-images/service-${key}.jpg`
          );
        }
      })
    ).then(() => {
      setProgress(0);
      themedAlert("Images reset to default successfully!");
    });
  }

  return (
    <div className="relative mt-10 SERVICES">
      <div className="top-0 z-20 sticky grid grid-cols-[auto_max-content] bg-white py-5">
        <div>
          <div className="grid grid-cols-[auto_max-content]">
            <h1 className="font-bold text-xl">Services</h1>
            <div className="flex gap-2 buttons">
              <Button className="border-gray-400 border" onClick={reset}>
                Reset images to default
              </Button>
              <Button className="border-gray-400 border" onClick={save}>
                Save images
              </Button>
            </div>
            <div
            className="items-center grid bg-gradient-to-br from-[#616c8a] to-[#3b455f] h-[20px] text-white text-xs transition-all overflow-hidden"
            style={{ width: `${progress}%` }}
          >
              <span className="px-3">Saving...</span>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div>
        <div className="gap-2 grid grid-cols-4">
          {Object.entries(serviceImages).map(([key, service], index) => (
            <div key={index}>
              <div className="text-nowrap text-sm max-lg:text-xs">{service.display}</div>
              <ImageChangableContainer
                filePickerClassName={key}
                isAdmin={true}
                imgClassName={`${key} w-full h-full object-cover object-center`}
                containerClassName="border border-gray-400 overflow-hidden aspect-square"
                image={service.image}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function ImageChangableContainer({
  onChange,
  image,
  imgClassName,
  containerClassName,
  isAdmin = false,
  onlyJpgAllowed,
  filePickerClassName,
}) {
  const [isImageFileSelectorOpen, setIsImageFileSelectorOpen] = useState(false);
  const [selectedImageFile, setSelectedImageFile] = useState({
    file: null,
    image: image,
  });
  useEffect(() => {
    setSelectedImageFile({
      file: image instanceof File ? image : null,
      image: image instanceof File ? null : image,
    });
    // 
    return () => {};
  }, [image]);

  return (
    <>
      <div className={"relative " + containerClassName}>
        {isAdmin && (
          <input
            onChange={(e) => {
              if (e.target.files?.[0].size >= 350 * 1024) {
                themedAlert(
                  "File should be less than 350 kb",
                  "red"
                );
                return;
              }
              if (onlyJpgAllowed && !helper.isJPG(e.target.files?.[0])) {
                themedAlert("Only jpg files allowed!", "red");
                return;
              }
              setSelectedImageFile({
                file: e.target.files?.[0],
                image:
                  e.target.files?.[0] && URL.createObjectURL(e.target.files[0]),
              });
            }}
            className={
              "bottom-0 left-0 absolute bg-white hover:bg-gray-100 p-1 w-fit max-w-[200px] max-lg:max-w-[94px] cursor-pointer text-xs " +
              filePickerClassName
            }
            type="file"
            name=""
          />
        )}
        <img
          data-preview="true"
          className={`object-center object-cover ${imgClassName} `}
          src={selectedImageFile.image}
          alt=""
        />
      </div>
    </>
  );
}

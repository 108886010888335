import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import LinedHeading from "../ui/LinedHeading";
import Button from "../ui/Button";
import Footer from "../components/Footer";
import Map from "../ui/Map";
import emailjs from "@emailjs/browser";
import { addEnquiries, addEnquiry } from "../lib/firebase";
import { FacebookSharp, Instagram } from "@mui/icons-material";
import themedAlert from "../lib/alert";

export default function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  // const [sendButtonText, setSendButtonText] = useState("SEND");
  const [messageSendStatusMessage, setMessageSendStatusMessage] = useState({
    statusMessage: "",
    success: true,
  });

  useEffect(() => {
    emailjs.init("topbtL_3-OqjYUJCq"); // Found in your EmailJS dashboard

    const submitButton = document.getElementById("submit-button");

    const handleClick = (e) => {
      e.preventDefault();
      const data = {
        name,
        email,
        phone,
        message,
        failedToMail: false,
      };
      
      emailjs
        .send("service_5jjvnke", "template_5852igs", data)
        .then(() => {
          setName("");
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
          setMessageSendStatusMessage({
            statusMessage: "Message sent successfully.",
            success: true,
          });
          setTimeout(() => {
            setMessageSendStatusMessage({
              statusMessage: "",
              success: true,
            });
          }, 1000);
        })
        .catch(() => {
          data.failedToMail = true;
          setMessageSendStatusMessage({
            statusMessage: "Message failed to send.",
            success: false,
          });
          setTimeout(() => {
            setMessageSendStatusMessage({ statusMessage: "", success: true });
          }, 1000);
        });
      addEnquiry({
        ...data,
        fromPage: "home",
      });

    };
    submitButton.addEventListener("click", handleClick);

    return () => {
      submitButton.removeEventListener("click", handleClick);
    };
  }, [name, email, phone, message]);

  return (
    <main className="bg-accent max-md:bg-darkaccent _h-screen text-white">
      <NavBar />
      <header className="max-lg:block gap-10 grid grid-cols-[min-content_auto_min-content] m-auto max-lg:m-auto px-[5rem]  w-full _max-w-[1400px] h-[calc(100vh-19rem)] min-h-[755px] max-lg:h-auto  max-lg:text-center container">
        <div className="flex justify-center items-center whitespace-nowrap">
          <div className="gap-[1.6rem] max-md:gap-3 grid font-extralight leading-tight  text-[17px] ">
            <h2 className=" font-normal text-[34px] max-md:text-[20px]">
              Ready to Start Your <br /> Bespoke Journey
            </h2>

            <p className="font-light text-[19.33px] leading-7 resetp max-md:text-[14px] max-md:leading-normal">
              Visit our boutique, call us, or book your <br /> appointment
              online.
            </p>
            <p className="font-light text-[17.33px] resetp  max-md:text-[14px]  max-md:leading-normal  leading-7">
              <font className="font-normal max-md:block max-md:mt-3">
                {" "}
                Address:
              </font>{" "}
              Meena Bazaar - Showroom # 10 & 11 Al Majid <br /> Property - Bur
              Dubai Al Souq Al Kabeer - Dubai
            </p>

            <p className="flex max-lg:m-auto font-light text-[16.33px] resetp  max-md:text-[14px]  max-md:leading-normal">
              <img className="mt-1 mr-2 h-4" src="/icons/call.png" alt="" />
              +971 50 455 4707 / +971 50 425 6989
            </p>
            <p className="flex max-lg:m-auto font-light text-[17.33px] resetp  max-md:text-[14px]  max-md:leading-normal">
              <img className="mt-1 mr-2 h-4" src="/icons/mail.png" alt="" />
              santoba@eim.ae
            </p>

            <p className="font-light resetp  max-md:text-[14px]  max-md:leading-normal">
              <font className="font-normal max-md:block max-md:mt-3">
                {" "}
                Operating hours:
              </font>
              <font className="ml-[2px]"> Saturday - Thursday</font> <br />
              <div className="lg:ml-[150px] leading-7">
                10.00 am - 2.00 pm <br />
                4.30 pm - 10.00 pm <br />
                <font> Friday</font> <br />
                4.30 pm - 10.00 pm
              </div>
            </p>
            <div className="gap-2 grid max-md:mt-5 max-lg:m-auto">
              <h2 className="font-light text-[12.38px]">CONNECT</h2>
              <div className="flex gap-3">
                <a href="https://www.instagram.com/santobatailors/">
                  <Instagram />
                  {/* <img className="h-[16px]" src="/icons/instagram.svg" alt="" /> */}
                </a>
                <a href="https://www.facebook.com/SantobaTailorsCo">
                  <FacebookSharp />
                  {/* <img className="h-[16px]" src="/icons/web.svg" alt="" /> */}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div className="flex justify-center items-center max-lg:py-10 w-full max-md:text-center RIGHT">
          <div className="lg:justify-start lg:grid w-full">
            <h2 className="max-lg:hidden mb-10 font-normal text-xl">
              Say hello!
            </h2>
            <form
              id="contact-form"
              className="justify-center grid w-full text-sm gap"
            >
              <Input
                value={name}
                onchange={(e) => {
                  setName(e.target.value);
                }}
                id={"fName"}
                placeholder="Name"
              />
              <Input
                value={phone}
                onchange={(e) => {
                  setPhone(e.target.value);
                }}
                id={"fPhone"}
                placeholder="Contact No"
              />
              <Input
                value={email}
                onchange={(e) => {
                  setEmail(e.target.value);
                }}
                id={"fEmail"}
                placeholder="Email"
              />
              <div>
                <label className="text-gray-400 block " htmlFor="">
                  Message
                </label>
                <textarea
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  className="border-gray-500 bg-accent p-2 border w-[30rem] md:max-w-[calc(100vw-5rem)] h-[10rem] outline-none resize-none max-md:w-full bg-[#ffffff0b] "
                  name=""
                  id="fMessage"
                ></textarea>
                {messageSendStatusMessage.statusMessage ? (
                  <div
                    className={`col-span-2  mt-1 p-1 font-bold text-[12px] text-center md:max-w-[calc(100vw-5rem)] w-[30rem] uppercase  ${
                      messageSendStatusMessage.success
                        ? " text-white"
                        : " text-white"
                    }`}
                  >
                    {messageSendStatusMessage.statusMessage}
                  </div>
                ) : <div className="h-[30px]"></div>}
                <Button
                  id={"submit-button"}
                  // type={"submit"}
                  className={
                    "mt-4 w-min whitespace-nowrap  max-lg:mx-auto px-[3.2rem]"
                  }
                >
                  SEND
                </Button>
              </div>
            </form>
          </div>
        </div>
      </header>
      <Map className={"h-[35rem] max-md:h-[20rem] "} />
      <Footer />
    </main>
  );
}

function Input({ placeholder, id, onchange, value }) {
  return (
    <>
      <input
        onChange={onchange}
        value={value}
        id={id}
        className="border-gray-400 focus:border-gray-50 max-lg:bg-[#ffffff0b] bg-transparent  max-lg:m-auto mb-5 border-b w-[20rem] max-lg:py-2 text-white max-lg:text-center focus:placeholder:text-gray-50 placeholder:text-gray-400 outline-none max-md:placeholder:text-[13px] max-md:text-[13px]"
        placeholder={placeholder}
        type="text"
      />
      <br />{" "}
    </>
  );
}

import React, { useEffect, useState } from "react";
import AdminLayout from "./AdminLayout";
import BlogsSideBar from "./BlogsSideBar";
import { getAllEnquiries } from "../../lib/firebase";
import themedAlert from "../../lib/alert";

export default function Enquiries() {
  const [enquiries, setEnquiries] = useState([]);
  useEffect(() => {
    getAllEnquiries().then((enq) => {
      setEnquiries(enq);
    });
  }, []);

  useEffect(() => {
    // Function to copy inner text
    const copyInnerText = (e) => {
      const textToCopy = e.target.innerText;
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          themedAlert(`Copied: "${textToCopy}"`);
        })
        .catch((err) => {
          
        });
    };

    // Attach event listener to all <p> elements
    const pElements = document.querySelectorAll('p');
    pElements.forEach((p) => {
      p.addEventListener('click', copyInnerText);
    });

    // Cleanup function to remove event listeners
    return () => {
      pElements.forEach((p) => {
        p.removeEventListener('click', copyInnerText);
      });
    };
  }, [enquiries]); // Re-run when enquiries are updated

  return (
    <AdminLayout>
      <section className="p-10 max-md:p-5 min-h-full">
        <ul className="gap-2 grid grid-cols-3 max-md:grid-cols-1">
          {enquiries.map((enq, index) => {
            return (
              <li className="justify-between border-gray-400 grid grid-cols-1 p-5 border text-sm overflow-hidden">
                <div>
                  <span className="flex gap-1">From: <p className="hover:text-blue-600 cursor-pointer resetp">
                    {enq.name}
                  </p></span> 
                  <p className="ml-[43px] hover:text-blue-600 cursor-pointer resetp">
                    {enq.phone}
                  </p>
                  <p className="ml-[43px] hover:text-blue-600 cursor-pointer resetp">
                    {enq.email}
                  </p>
                  <p className="border-gray-500 my-3 pl-3 border-l-2 hover:text-blue-600 cursor-pointer resetp">
                    {enq.message}
                  </p>
                </div>
                {/* <p className="hover:text-blue-600 cursor-pointer resetp">{enq.fromPage}</p> */}
                {/* <p className="hover:text-blue-600 cursor-pointer resetp">{enq.failedToMail}</p> */}
                <div className="text-right m-0 w-full text-xs">
                  <p className="hover:text-blue-600 cursor-pointer resetp">
                    {new Date(parseInt(enq.createdAt)).toDateString()}
                  </p>
                  <p className="hover:text-blue-600 cursor-pointer resetp">
                    {new Date(parseInt(enq.createdAt)).toLocaleTimeString()}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      </section>
    </AdminLayout>
  );
}

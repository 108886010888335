import React, { useState } from "react";
import NavBar from "../components/NavBar";
import LinedHeading from "../ui/LinedHeading";
import CenteredPara from "../ui/CenteredPara";
import Footer from "../components/Footer";
import { defaultAboutUsFirebasePath } from "./admin/WebsiteImageManager";

export default function AboutUs() {
  const [aboutusImage, setAboutusImage] = useState(defaultAboutUsFirebasePath)
  return (
    <main className="bg-accent max-md:bg-darkaccent text-white">
      <NavBar pageTitle={"OUR STORY"} />
      <LinedHeading
        className={"pb-[1rem] max-md:hidden"}
        heading={"ABOUT US"}
      />
      <h2 className=" leading-tight heading text-center md:hidden  max-md:text-[18px]">
        A FAMILY LEGACY <br />
        OF CRAFTSMANSHIP AND DEDICATION
      </h2>
      <div className="grid grid-cols-2 m-auto max-w-[1500px] container max-lg:grid-cols-1 md:pt-[3rem] pb-[66px] md:px-[5rem] _h-[calc(100vh-10rem)] min-h-fit">
        <div className="flex flex-col  px-5  justify-center pl-5 max-lg:order-2 max-xl:text-center max-xl:m-auto">
          <h2 className="mb-10 leading-tight heading max-md:hidden">
            A FAMILY LEGACY <br />
            OF CRAFTSMANSHIP <br />
            AND DEDICATION,
          </h2>
          <p className="max-w-xl pb-[3rem] max-sm:px-[8px] text-[16.33px] font-light text-white max-md:max-w-xl max-md:px-2 pt-1  max-md:text-[15px] max-md:leading-[1.5] max-md:pb-0 ">
            Founded in Dubai’s Meena Bazaar by two visionary brothers, Santoba
            Tailors has grown into a trusted name in luxury tailoring. Our
            journey began with a commitment to precision, personalized service,
            and a refined approach to style. Today, we continue to blend
            heritage craftsmanship with contemporary design, creating garments
            that define sophistication and individuality.{" "}
          </p>
        </div>
        <div className=" max-lg:order-1 grid items-center  max-md:px-5 max-lg:py-12">
          <img
            data-preview="false"
            src={aboutusImage}
            alt=""
            // className="p-10 px-1 max-md:px-5 max-md:h-[400px]  w-full h-full object-cover object-center max-xl:h-[40rem] _lg:w-[calc(70vh-3rem)] _lg:h-[calc(70vh-3rem)] md:h-[600px] md:w-[600px] self-end mr-0 ml-auto lg:aspect-square"
            className="md:h-[650px] md:w-[450px] m-auto max-md:max-w-[300px] object-cover"
            />
        </div>
      </div>

      <Footer />
    </main>
  );
}

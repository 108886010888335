import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop";
import SingleService from "./components/SingleService";
import AboutUs from "./pages/AboutUs";
import Blog from "./pages/Blog";
import ContactUs from "./pages/ContactUs";
import Corporates from "./pages/Corporates";
import Home from "./pages/home";
import LookBook from "./pages/LookBook";
import ProcessAndFabrics from "./pages/ProcessAndFabrics";
import Services from "./pages/Services";
import Wedding from "./pages/Wedding";
import LookbookManager from "./pages/admin/LookbookManager";
import BlogManager from "./pages/admin/BlogManager";
import Faq from "./pages/Faq";
import BlogArticle from "./pages/BlogArticle";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Test from "./pages/admin/Test";
import Enquiries from "./pages/admin/Enquiries";
import Subscriptions from "./pages/admin/Subscriptions";
import ImageManager from "./pages/admin/WebsiteImageManager";

// View an image.

const router = createBrowserRouter([
  // {
  //   path: "/test",
  //   element: (
  //     <Test />
  //   ),
  // },
  {
    path: "/",
    element: (
      <ScrollToTop>
        {" "}
        <Home />{" "}
      </ScrollToTop>
    ),
  },
  {
    path: "/lookbook",
    element: (
      <ScrollToTop>
        <LookBook />
      </ScrollToTop>
    ),
  },
  {
    path: "/blog",
    element: (
      <ScrollToTop>
        <Blog />
      </ScrollToTop>
    ),
  },
  {
    path: "/blog/:blogId",
    element: (
      <ScrollToTop>
        <BlogArticle />
      </ScrollToTop>
    ),
  },
  {
    path: "/wedding",
    element: (
      <ScrollToTop>
        <Wedding />
      </ScrollToTop>
    ),
  },
  {
    path: "/corporates",
    element: (
      <ScrollToTop>
        <Corporates />
      </ScrollToTop>
    ),
  },
  {
    path: "/services",
    element: (
      <ScrollToTop>
        <Services />
      </ScrollToTop>
    ),
  },
  {
    path: "/processandfabrics",
    element: (
      <ScrollToTop>
        <ProcessAndFabrics />
      </ScrollToTop>
    ),
  },
  {
    path: "/aboutus",
    element: (
      <ScrollToTop>
        <AboutUs />
      </ScrollToTop>
    ),
  },
  {
    path: "/contactus",
    element: (
      <ScrollToTop>
        <ContactUs />
      </ScrollToTop>
    ),
  },
  {
    path: "/faq",
    element: (
      <ScrollToTop>
        <Faq />
      </ScrollToTop>
    ),
  },
  {
    path: "/respoketailoring",
    element: (
      <ScrollToTop>
        <SingleService
          heading={"RESPOKE TAILORING"}
          serviceIndex={0}
          introduction="For your special day, nothing less than perfect will do. Santoba Tailors offers bespoke wedding attire that exudes elegance, sophistication, and a personal touch. From the wedding party, we craft timeless pieces that ensure everyone looks their best."
        />
      </ScrollToTop>
    ),
  },
  {
    path: "/alterationsandstyling",
    element: (
      <ScrollToTop>
        <SingleService
          heading={"ALTERATIONS & STYLING"}
          serviceIndex={1}
          introduction="Transform your professional wardrobe with our tailored corporate packages. Whether you need everyday suits, business-casual attire, or versatile blazers, Santoba Tailors brings style, comfort, and confidence to your workplace"
        />
      </ScrollToTop>
    ),
  },
  {
    path: "/footwearandaccessories",
    element: (
      <ScrollToTop>
        <SingleService
          heading={"FOOTWEAR & ACCESSORIES"}
          serviceIndex={2}
          introduction=""
        />
      </ScrollToTop>
    ),
  },
  {
    path: "/admin",
    element: (
      <ScrollToTop>
        <LookbookManager />{" "}
      </ScrollToTop>
    ),
  },
  {
    path: "/admin/blog",
    element: (
      <ScrollToTop>
        <BlogManager />{" "}
      </ScrollToTop>
    ),
  },
  {
    path: "/admin/blog/:blogId",
    element: (
      <ScrollToTop>
        <BlogManager />{" "}
      </ScrollToTop>
    ),
  },
  {
    path: "/admin/lookbook",
    element: (
      <ScrollToTop>
        <LookbookManager />{" "}
      </ScrollToTop>
    ),
  },
  {
    path: "/admin/enquiries",
    element: (
      <ScrollToTop>
        <Enquiries />{" "}
      </ScrollToTop>
    ),
  },
  {
    path: "/admin/subscriptions",
    element: (
      <ScrollToTop>
        <Subscriptions />{" "}
      </ScrollToTop>
    ),
  },
  {
    path: "/admin/imagemanager",
    element: (
      <ScrollToTop>
        <ImageManager />{" "}
      </ScrollToTop>
    ),
  },

]);
function App() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return <RouterProvider router={router} />;
}

export default App;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar, { NavBarContent } from "../components/NavBar";
import Button from "../ui/Button";
import CenteredPara from "../ui/CenteredPara";
import LinedHeading from "../ui/LinedHeading";
import LinedHeading2 from "../ui/LinedHeading2";
import Map from "../ui/Map";
import Swipable from "../ui/Swipable";
import emailjs from "@emailjs/browser";
import { addEnquiry, getData } from "../lib/firebase";
import themedAlert from "../lib/alert";
import instaImages from "./instagramImages";
import { defaulHomeImagesFromFirebase, defaultHomeImages, firebaseStorageMediaAddress } from "./admin/WebsiteImageManager";

export const testimonials = [
  {
    content:
      "The quality of work done by Rajesh and his team in crafting the suits for me was absolutely amazing. I got my wedding tuxedo done from here and then went back to get more suits. The attention to detail is impeccable, from the precise measurements to the flawless stitching. The fabric feels luxurious, the choice of unique colours is vast and the fit is absolutely perfect. Thank you for the amazing work!",
    author: "Edwin Cardoza",
  },
  {
    content:
      "Santoba is my favorite place for custom clothing. They offer high-quality fabrics and have excellent tailors. The best thing about Santoba is their deep understanding of customer requirements and their dedication to ensuring customers get exactly what they desire. Additionally, the shop is budget-friendly, with reasonable prices. I highly recommend this place, and for personalized attention and assistance, be sure to meet Ali.",
    author: "Jaz man",
  },
  {
    content:
      "Best Clothing stands out for its exceptional designs and expert tailoring. Each garment is tailored to perfection, offering a precise fit that enhances both comfort and style. The cuts are flattering, and the fabric choices are top-notch, resulting in clothing that feels luxurious yet functional. Best Clothing’s design expertise and tailoring craftsmanship make them a go-to for anyone seeking well-fitted, stylish attire.",
    author: "Abdur Rahaman Fuzail",
  },
  {
    content:
      "I recently wore a checkered blue sports coat from Santoba Tailors, and even at the Chicago airport security line, it got noticed! The gentleman in front of me complimented the fit and pattern, which says everything about the skill of Ali and his team at Santoba. Ali is like a style advisor who really understands how to blend sharp, business-appropriate style with just the right amount of flair. His recommendations have transformed my wardrobe in ways I didn’t expect.",
    author: "Sajid Mullaji",
  },
  {
    content:
      "My husband and I have been using Santoba since 2008... it is our go to place for all our suits and shirts for both my husband and myself. Their service is top notch and very detailed... it maybe a monogram, buttons, threadwork... the clothes always come out looking superior and stands apart. Prakash and Rajesh go out of their way to make each customer needs met and I really value their opinion on fabric selection and latest styles as they are so knowledgeable.. and above all they care!",
    author: "Irene Samuel",
  },
];

export default function Home() {
  const location = useLocation();
  const [homeImages, setHomeImages] = useState(defaulHomeImagesFromFirebase);

  const { state } = location || {};
  useEffect(() => {
    window.history.replaceState({}, "");
  }, []);

  return (
    <>
      {!state?.fromNav && <div className="blackfade"></div>}
      <main className="relative bg-accent max-md:bg-darkaccent">
        <header
          className="relative bg-cover bg-center h-screen max-md:h-[400px] md:min-h-[50rem] overflow-hidden"
          style={homeImages && { backgroundImage: `url(${firebaseStorageMediaAddress(`home-head.jpg`)})` }}
        >

          <nav className="relative z-10 bg-gradient-to-b from-black h-[20rem] max-md:h-[10rem]">
            <NavBar isHome={true} />
          </nav>
          <div className="relative bottom-0 max-md:absolute pl-[6rem] max-md:pl-[0] max-lg:pl-[4rem] w-full text-white pointer-events-none">
            <div className="bottom-0 z-0 absolute md:hidden bg-gradient-to-t from-darkaccent w-full h-[10rem] SHADE"></div>
            <h1 className="max-md:bottom-[4rem] z-0 max-md:absolute m-auto w-full font-medium max-md:font-serif text-[47.49px] max-md:text-center max-md:text-[30px] max-lg:text-[2.7rem] md:uppercase leading-tight">
              <span className="border-white md:pl-5 md:border-l-4">Where</span>{" "}
              <br />
              <div className="md:pl-5">
                Craftsmanship <br />
                Meets <br className="max-md:hidden" />
                Individuality
              </div>
            </h1>
            <p className="max-md:hidden pt-5 pl-5 font-medium text-[18.45px] uppercase">
              Since 2006, Santoba Tailors redefines bespoke with <br /> timeless
              sophistication
            </p>
          </div>
        </header>
        <div className="md:hidden">
          <WhatWeOffer />
        </div>
        <div className="max-md:hidden">
          <MissionAndVision images={homeImages} />
        </div>
        <ShortLookBook images={homeImages} />
        <div className="md:hidden">
          <MissionAndVisionInPhone images={homeImages} />
        </div>

        <Testimonials />
        <ImagesAfterTestimonial images={homeImages} />
        <OnWheels />
        <Appoinment />
        <Footer />
      </main>
    </>
  );
}

function WhatWeOffer() {
  return (
    <section className="bg-darkaccent pb-10">
      <LinedHeading2 heading={"WHAT WE OFFER"} />
      <div>
        <p className="font-normal text-center text-white text-xl max-sm:text-[14px] leading-[2]">
          Everyday Wear | Corporate Attire | Wedding Attire <br />
          Alterations & Styling | Accessories
        </p>
      </div>
    </section>
  );
}

function MissionAndVisionInPhone() {
  return (
    <>
      <section>
        <LinedHeading2 heading={"OUR MISSION"} />
        <CenteredPara
          className={
            "text-white max-w-xl px-1 pt-1  max-md:text-[14px] max-md:leading-[2] pb-0"
          }
          content={
            "To craft bespoke garments that blend tradition with modernity, reflecting each client’s unique style through exceptional craftsmanship and personalized service"
          }
        />
      </section>
      <section>
        <LinedHeading2 heading={"OUR VISION"} />
        <CenteredPara
          className={
            "text-white max-w-xl px-1 pt-1  max-md:text-[14px] max-md:leading-[2] pb-0"
          }
          content={
            "To be the leading name in bespoke tailoring, known globally for our craftsmanship, innovation, and dedication to creating timeless pieces that elevate personal style"
          }
        />
      </section>
    </>
  );
}

function MissionAndVision({ images }) {
  return (
    <section className="grid grid-rows-2 bg-accent _h-screen text-white overflow-hidden">
      <div className="grid grid-cols-2 min-h-[23rem] MISSION">
        <div className="items-center grid px-12 LEFT">
          <div>
            <h2 className="pb-[3rem] font-normal heading">OUR MISSION</h2>
            <p className="max-w-xl font-light text-[19px] max-md:text-[10px] leading-loose max-md:leading-3">
              To craft bespoke garments that blend tradition with modernity,
              reflecting each client’s unique style through exceptional
              craftsmanship and personalized service.
            </p>
          </div>
        </div>
        <div className="overflow-hidden RIGHT">
          <img className="w-full h-full" src={images.mission} alt="" />
        </div>
      </div>
      <div className="grid grid-cols-2 min-h-[23rem] VISION">
        <div className="overflow-hidden LEFT">
          <img className="w-full h-full" src={images.vision} alt="" />
        </div>

        <div className="items-center grid px-12 RIGHT">
          <div>
            <h2 className="pb-[3rem] font-normal heading">OUR VISION</h2>
            <p className="max-w-xl font-light text-[19px] max-md:text-[10px] leading-loose max-md:leading-4 *:">
              To be the leading name in bespoke tailoring, known globally for
              our craftsmanship, innovation, and dedication to creating timeless
              pieces that elevate personal style.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

function ShortLookBook({ images = defaultHomeImages }) {
  const navigate = useNavigate();
  return (
    <section className="bg-accent max-md:bg-darkaccent">
      <div
        id="images"
        className="gap-0 grid grid-cols-4 max-md:grid-cols-1 max-md:px-4 overflow-hidden aspect-auto IMAGES"
      >
        {" "}
        {images.fourLookbookImages.map((item, index) => {
          return (
            <img
              data-preview="false"
              className="w-full aspect-[4/5] object-cover"
              src={item}
              alt=""
            />
          );
        })}
      </div>
      <div className="py-10 text-center">
        <Button
          className={"m-auto"}
          onClick={() => {
            navigate("/lookbook", { viewTransition: true });
          }}
        >
          VIEW GALLERY
        </Button>
      </div>
    </section>
  );
}

function Testimonials() {
  return (
    <section className="bg-accent max-md:bg-darkaccent p-5 text-white">
      <LinedHeading heading="TESTIMONIAL" />
      <Swipable data={testimonials} />
    </section>
  );
}

function ImagesAfterTestimonial({ images = defaultHomeImages }) {
  return (
    <section className="gap-3 max-md:gap-0 grid grid-cols-4 bg-accent">
      {/* <div className="gap-3 max-md:gap-0 grid grid-cols-4"> */}
      {images.eightInstaImages.map((image, index) => (
        <img data-preview="true" className="w-full aspect-square object-cover object-center" src={image} alt="" />
      ))}
    </section>
  );
}

function OnWheels() {
  const navigate = useNavigate();
  return (
    <section className="max-xl:block relative grid grid-cols-[30rem_auto] bg-[#22242E] max-md:bg-darkaccent mt-[9rem] max-md:mt-[1rem] w-full text-white">
      <div className="max-xl:hidden w-full LEFT">
        <img
          className="w-full h-full object-cover"
          src="/home/onwheels.png"
          alt=""
        />
      </div>
      <div className="max-xl:block justify-between grid max-xl:m-auto p-9 xl:pl-[5rem] max-xl:text-center RIGHT">
        <LinedHeading2 className={"md:hidden"} heading={"SANTOBA ON WHEELS"} />
        <h2 className="max-md:hidden heading">SANTOBA ON WHEELS</h2>
        <p className="max-xl:m-auto px-2 max-md:px-0 pt-1 pb-0 max-w-[50rem] max-md:max-w-xl font-light text-[19px] text-white max-md:text-[15px] leading-relaxed max-md:leading-[2]">
          We bring the Santoba experience to you. Our expert tailors offer
          personalized service at your home or office, complete with our premium
          fabric selection and precise measurements, Experience luxury tailoring
          at your convenience. Our expert tailors bring the Santoba experience
          to your home or office, complete with premium fabric selection and
          precise measurements. Enjoy bespoke service wherever you feel most
          comfortable providing the luxury of bespoke tailoring at your
          convenience, where you feel most comfortable.
        </p>
      </div>
      <div>
        <div className="top-1/2 right-0 fixed w-0 h-0 rotate-90">
          <Button
            className={" -translate-x-1/2 whitespace-nowrap max-xl:hidden"}
            onClick={() => {
              navigate("/contactus", { viewTransition: true });
            }}
          >
            BOOK A CONSULTATION
          </Button>
        </div>
      </div>
    </section>
  );
}

function Appoinment() {
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [sendButtonText, setSendButtonText] = useState("BOOK A CONSULTATION");
  const [messageSendStatusMessage, setMessageSendStatusMessage] = useState({
    statusMessage: "",
    success: true,
  });
  useEffect(() => {
    emailjs.init("topbtL_3-OqjYUJCq"); // Found in your EmailJS dashboard

    const submitButton = document.getElementById("submit-button");

    const handleClick = (e) => {
      e.preventDefault();
      // setSendButtonText("Sending...");
      const data = {
        name,
        email,
        phone,
        message,
        failedToMail: false,
      };
      
      emailjs
        .send("service_5jjvnke", "template_5852igs", data)
        .then(() => {
          // setSendButtonText("SUCCESS");
          setMessageSendStatusMessage({
            statusMessage: "Message sent successfully.",
            success: true,
          });
          setName("");
          setFirstName("");
          setSecondName("");
          setEmail("");
          setPhone("");
          setMessage("");
          setTimeout(() => {
            // setSendButtonText("BOOK A CONSULTATION");
            setMessageSendStatusMessage({ statusMessage: "", success: true });
          }, 1000);
        })
        .catch(() => {
          data.failedToMail = true;
          // setSendButtonText("RESEND");
          setMessageSendStatusMessage({
            statusMessage: "Message failed to send.",
            success: false,
          });
          setTimeout(() => {
            setMessageSendStatusMessage({ statusMessage: "", success: true });
            // setSendButtonText("BOOK A CONSULTATION");
          }, 1000);
        });

      addEnquiry({
        ...data,
        fromPage: "home",
      });
    };
    submitButton.addEventListener("click", handleClick);

    return () => {
      submitButton.removeEventListener("click", handleClick);
    };
  }, [name, email, phone, message]);

  return (
    <section className="max-lg:block grid grid-cols-2 bg-accent max-md:bg-darkaccent text-white">
      <div className="max-lg:hidden LEFT">
        {/* <img src="home/maskmap.png" alt="" className="w-full h-full" /> */}
        <Map className={"h-full"} />
      </div>
      <div className="max-lg:block flex justify-center items-center max-lg:mx-4 py-10 RIGHT">
        <div className="">
          <div className="pb-8 text-center leading-tight HEADINGS">
            <h2 className="max-lg:text-[20px] heading">BOOK AN APPOINMENT</h2>
            <p className="p-0 text-[18px] max-lg:text-[13px] serif">
              GET YOUR SLOTS NOW
            </p>
          </div>
          <form className="max-lg:block gap-4 grid grid-cols-2 max-md:w-full text-black">
            <FormInput
              value={firstName}
              onchange={(e) => {
                setFirstName(`${e.target.value}`);
                setName(`${e.target.value} ${secondName}`);
              }}
              placeholder={"FIRST NAME"}
            />
            <FormInput
              value={secondName}
              onchange={(e) => {
                setSecondName(`${e.target.value}`);
                setName(`${firstName} ${e.target.value}`);
              }}
              placeholder={"LAST NAME"}
            />
            <FormInput
              value={email}
              placeholder={"E MAIL"}
              onchange={(e) => {
                setEmail(`${e.target.value}`);
              }}
            />
            <FormInput
              value={phone}
              placeholder={"TELEPHONE NUMBER"}
              onchange={(e) => {
                setPhone(`${e.target.value}`);
              }}
            />
            <textarea
              placeholder="TYPE YOUR MESSAGE"
              value={message}
              onChange={(e) => {
                setMessage(`${e.target.value}`);
              }}
              className={`border-gray-100 col-span-2 bg-accent max-md:bg-darkaccent px-2 py-3 pl-3 border w-full  max-w-[40rem] max-lg:max-w-full max-lg:w-full text-white placeholder:text-[#b6b6b6] placeholder:text-[11px] outline-none resize-none ${
                messageSendStatusMessage.statusMessage
                  ? "h-[112px]"
                  : " h-[10rem] "
              }`}
              name=""
              id=""
            ></textarea>
            {messageSendStatusMessage.statusMessage && (
              <div
                className={`col-span-2  mt-1 p-1 w-full font-bold text-[12px] text-center uppercase   ${
                  messageSendStatusMessage.success
                    ? " text-white"
                    : " text-white"
                }`}
              >
                {messageSendStatusMessage.statusMessage}
              </div>
            )}
          </form>
          <div className="flex">
            <Button
              id="submit-button"
              className="m-auto mt-4 max-lg:mb-[3.5rem]"
            >
              {sendButtonText}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

function FormInput({ onchange, value, placeholder, id }) {
  return (
    <input
      required={true}
      onChange={onchange}
      value={value}
      id={id}
      className="max-lg:block bg-[#C3C3C3] md:focus:bg-white max-md:bg-[#ffffff0e] max-lg:m-auto max-lg:mb-5 px-2 py-2 pl-[13px] max-md:border-b max-md:w-full max-lg:w-full max-lg:max-w-sm max-md:text-white max-lg:text-center placeholder:text-[#7D7D7D] placeholder:text-[11px] outline-none"
      type="text"
      placeholder={placeholder}
    />
  );
}

import "../../lib/firebase";
import React, { useEffect, useRef, useState } from "react";
import he from 'he'
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Delete,
  Edit,
} from "@mui/icons-material";
import MediumEditor from "medium-editor";
import "medium-editor/dist/css/medium-editor.css";
import "medium-editor/dist/css/themes/default.css";
import {
  OneImage,
  ParaWithImage,
  ParaWithOnlyHeading,
  TwoImages,
} from "../BlogArticle";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonGroup } from "@mui/material";
import {
  addBlog,
  deleteBlog,
  editBlog,
  getBlogByDocId,
} from "../../lib/firebase";
import { BlogItem } from "../Blog";
export default function BlogEditor() {
  const [coverPreviewOpen, setCoverPreviewOpen] = useState(true);
  const { blogId } = useParams();
  const navigate = useNavigate();
  const editorRef = useRef();

  const defaultBlog = {
    id: "blog-1",
    isPublished: false,
    heading: "",
    link: "",
    images: ["/blog/1.png"],
    article: [
      {
        type: "intro",
        heading: "",
        content: ` `,
        image: "https://placehold.co/600x600",
        imageOne: "",
        imageTwo: "",
      },
    ],
  };
  const [blog, setBlog] = useState(defaultBlog);
  useEffect(() => {
    editorRef.current = new MediumEditor("#editable", {
      toolbar: {
        allowMultiParagraphSelection: true,
        buttons: ["bold", "italic", "underline", "anchor", "unorderedlist"],
        diffLeft: 0,
        diffTop: -10,
        static: false,
        align: "left",
        sticky: false,
        updateOnEmptySelection: false,
      },
      placeholder: {
        text: "Double click here...",
      },
    });

    return () => {
      // Cleanup editor on component unmount
      if (editorRef.current) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, [blog, blogId]); // Empty dependency array ensures this runs only once

  // Handle blog state logic
  useEffect(() => {
    if (blogId !== "new" && blogId) {
      getBlogByDocId(blogId).then((b) => {
        setBlog(b || defaultBlog);
      });
    } else {
      navigate("/admin/blog/new");
      setBlog(defaultBlog);
    }
  }, [blogId]);

  function getInnerHtmlOf(selector) {
    return window.document.querySelector(selector)?.innerHTML;
  }
  const saveBlogSectionModificationsToStateAndGetData = async () => {
    const blogToSave = {}; //defaultBlog;
    console.log("saving started");
    const heading = getInnerHtmlOf(".article-heading");
    blogToSave.heading = heading || null;
    blogToSave._id = blog._id;
    blogToSave.coverImage =
      window.document.querySelector(`.article-cover-image`)?.src || null;
    blogToSave.article = [];
    blogToSave.isPublished = false;

    blog.article.forEach((para, index) => {
      let paraToSave = {
        heading: getInnerHtmlOf(`.article-para-${index}-heading`) || null,
        content: getInnerHtmlOf(`.article-para-${index}-content`) || null,
        image:
          window.document.querySelector(`.article-para-${index}-image`)?.src ||
          null,
        imageOne:
          window.document.querySelector(`.article-para-${index}-image-one`)
            ?.src || null,
        imageTwo:
          window.document.querySelector(`.article-para-${index}-image-two`)
            ?.src || null,
        type:
          window.document
            .querySelector(`.article-container-${index}`)
            .getAttribute("data-container-type")
            ?.toString() || null,
      };
      blogToSave.article?.push(paraToSave);
    });
    setBlog(blogToSave);
    console.log({ blogToSave });
    delete blogToSave._id;
    return blogToSave;
  };

  const saveToDb = (valuesToOverwriteInBlogToSave) => {
    if (blogId === "new" && blogId) {
      saveBlogSectionModificationsToStateAndGetData().then((blogToSave) => {
        blogToSave = { ...blogToSave, ...valuesToOverwriteInBlogToSave };
        console.log("Adding new blog");
        addBlog(blogToSave).then((addedDocId) => {
          navigate(`/admin/blog/${addedDocId}`);
        });
      });
    } else {
      console.log("Updating the blog");
      saveBlogSectionModificationsToStateAndGetData().then((blogToSave) => {
        blogToSave = { ...blogToSave, ...valuesToOverwriteInBlogToSave };
        setBlog(blogToSave);
        editBlog(blogId, blogToSave).then(() => {
          // window.location.reload()
          // alert("Updated");
        });
      });
    }
  };
  return (
    <div className="relative grid grid-rows-[80px_auto] overflow-hidden">
      <div className="top-0 z-10 sticky items-center grid grid-cols-[auto_min-content] bg-white p-2 w-full text-black">
        <div className="ml-[25px] text-sm">
          {/* blogId !== "new" && (
            <>
              <span className="flex gap-2">
                Link:{" "}
                <p className="text-sm blog-link">
                  {`${window.location.host}${blog.link}`}{" "}
                </p>
              </span>
            </>
          )} */}
        </div>
        <div className="gap-1 grid grid-flow-col overflow-auto">
          <ButtonGroup>
            {/* blogId != "new" && (
              <Button
                onClick={() => {
                  var text = `${window.location.host}${blog.link}`;
                  navigator.clipboard.writeText(text).then(
                    function () {
                      console.log(
                        "Async: Copying to clipboard was successful!"
                      );
                    },
                    function (err) {
                      console.error("Async: Could not copy text: ", err);
                    }
                  );
                }}
                className="w-max"
              >
                Copy link
              </Button>
            )} */}
            {blogId != "new" && (
              <>
                <Button
                  onClick={() => {
                    deleteBlog(blogId).then(() => navigate("/admin/blog/new"));
                  }}
                  className="w-max"
                >
                  Delete blog
                </Button>
              </>
            )}
            <Button onClick={() => saveToDb()}>Save</Button>
            {!blog.isPublished ? (
              <Button
                onClick={() => {
                  saveToDb({ isPublished: true });
                }}
              >
                Publish
              </Button>
            ) : (
              <Button
                onClick={() => {
                  saveToDb({ isPublished: false });
                }}
              >
                Unpublish
              </Button>
            )}
          </ButtonGroup>
        </div>
      </div>
      <div className={`md:p-[3rem] p-10 pl-[4rem]`}>
        {/* COVER PREVIEW */}
        <div
          className={`bg-black text-white overflow-hidden transition-all ${
            coverPreviewOpen ? "h-[550px]" : "h-[5rem]"
          }`}
        >
          <div
            className="items-center grid bg-black w-full h-[5rem] text-center cursor-pointer select-none"
            onClick={() => {
              setCoverPreviewOpen(!coverPreviewOpen);
            }}
          >
            <span>
              {coverPreviewOpen ? (
                <>
                  COLLAPSE COVER IMAGE <ArrowUpward />
                </>
              ) : (
                <>
                  EDIT COVER IMAGE <ArrowDownward />
                </>
              )}
            </span>
          </div>

          {coverPreviewOpen && (
            <BlogItem
              key={blog._id}
              containerClassName={"bg-black p-10 px-15"}
              content={blog.article[0].content}
              heading={blog.heading}
              imageClassName={`article-cover-image`}
              imageUrl={blog.coverImage}
              isImageLeft={true}
              link={blog.link}
              isAdmin={true}
            />
          )}
        </div>

        <div
          data-container-type="intro"
          className={`article-container-0 mt-[5rem]`}
        >
          <h1
            id={!blog.isPublished ? "editable" : ""}
            className={
              "mb-10 text-center leading-tight heading " + `article-heading`
            }
            contentEditable={!blog.isPublished}
            dangerouslySetInnerHTML={{ __html: he.decode(blog.heading) }}
          ></h1>
          <p
            id={!blog.isPublished ? "editable" : ""}
            className={
              "m-auto max-md:mt-[4rem] md:pt-10 font-light text-wrap text-center resetp showlist " +
              `article-intro article-para-0-content`
            }
            contentEditable={!blog.isPublished}
            dangerouslySetInnerHTML={{ __html: he.decode(blog.article[0].content) }}
          ></p>
        </div>
        {blog.article.map((articleSection, index) => {
          if (articleSection.type === "intro") return <div key={index}></div>;
          else if (articleSection.type === "left-imaged-para")
            return (
              <div
                key={index + blog?._id + blog?.id?.toString()}
                data-container-type="left-imaged-para"
                className={`article-container-${index} relative group`}
              >
                <BlogArticleSectionActions
                  key={index + blog?._id + blog?.id?.toString()}
                  sectionIndex={index}
                  setBlog={setBlog}
                  blog={blog}
                  saveBlogSectionModificationsToState={
                    saveBlogSectionModificationsToStateAndGetData
                  }
                />
                <ParaWithImage
                  key={index + blog?._id + blog?.id?.toString()}
                  imagePosition={"left-imaged-para"}
                  image={articleSection.image}
                  imgClassName={`article-para-${index}-image`}
                  heading={articleSection.heading}
                  para={articleSection.content}
                  isAdmin={!blog.isPublished}
                  headingClassName={`article-para-${index}-heading`}
                  paragraphClassName={`article-para-${index}-content`}
                />
              </div>
            );
          else if (articleSection.type === "right-imaged-para")
            return (
              <div
                key={index + blog?._id + blog?.id?.toString()}
                data-container-type="right-imaged-para"
                className={`article-container-${index} relative group`}
              >
                <BlogArticleSectionActions
                  sectionIndex={index}
                  setBlog={setBlog}
                  blog={blog}
                  saveBlogSectionModificationsToState={
                    saveBlogSectionModificationsToStateAndGetData
                  }
                />
                <ParaWithImage
                  imagePosition={"right-imaged-para"}
                  image={articleSection.image}
                  imgClassName={`article-para-${index}-image`}
                  heading={articleSection.heading}
                  para={articleSection.content}
                  isAdmin={!blog.isPublished}
                  headingClassName={`article-para-${index}-heading`}
                  paragraphClassName={`article-para-${index}-content`}
                />
              </div>
            );
          else if (articleSection.type === "one-image")
            return (
              <div
                key={index + blog?._id + blog?.id?.toString()}
                data-container-type="one-image"
                className={`article-container-${index} relative group`}
              >
                <BlogArticleSectionActions
                  sectionIndex={index}
                  setBlog={setBlog}
                  blog={blog}
                  saveBlogSectionModificationsToState={
                    saveBlogSectionModificationsToStateAndGetData
                  }
                />
                <OneImage
                  isAdmin={!blog.isPublished}
                  image={articleSection.imageOne}
                  imgClassName={`article-para-${index}-image-one`}
                />
              </div>
            );
          else if (articleSection.type === "two-images")
            return (
              <div
                key={index + blog?._id + blog?.id?.toString()}
                data-container-type="two-images"
                className={`article-container-${index} relative group`}
              >
                <BlogArticleSectionActions
                  sectionIndex={index}
                  setBlog={setBlog}
                  blog={blog}
                  saveBlogSectionModificationsToState={
                    saveBlogSectionModificationsToStateAndGetData
                  }
                />
                <TwoImages
                  isAdmin={!blog.isPublished}
                  twoimages={[articleSection.imageOne, articleSection.imageTwo]}
                  imgOneClassName={`article-para-${index}-image-one`}
                  imgTwoClassName={`article-para-${index}-image-two`}
                />
              </div>
            );
          else
            return (
              <div
                key={index + blog?._id + blog?.id?.toString()}
                data-container-type="para-with-only-heading"
                className={`article-container-${index}`}
              >
                <BlogArticleSectionActions
                  sectionIndex={index}
                  setBlog={setBlog}
                  blog={blog}
                  saveBlogSectionModificationsToState={
                    saveBlogSectionModificationsToStateAndGetData
                  }
                />
                <ParaWithOnlyHeading
                  heading={articleSection.heading}
                  para={articleSection.content}
                  isAdmin={!blog.isPublished}
                  headingClassName={`article-para-${index}-heading`}
                  paragraphClassName={`article-para-${index}-content`}
                />
              </div>
            );
        })}
        <NewSectionButton blog={blog} setBlog={setBlog} />
      </div>
    </div>
  );
}

function NewSectionButton({ blog, setBlog }) {
  const [isNewSectionSelectorOpen, setIsNewSectionSelectorOpen] =
    useState(false);
  const newSectionItems = [
    {
      display: "Paragraph with heading",
      value: "para-with-only-heading",
      icon: <></>,
    },
    {
      display: "Paragraph with image in right",
      value: "right-imaged-para",
      icon: <></>,
    },
    {
      display: "Paragraph with image in left",
      value: "left-imaged-para",
      icon: <></>,
    },
    {
      display: "A long photo",
      value: "one-image",
      icon: <></>,
    },
    {
      display: "Two photoes",
      value: "two-images",
      icon: <></>,
    },
  ];
  return (
    <div className="border-2 border-gray-400 hover:border-white mt-9 p-5 border-dashed w-full text-center text-gray-400 hover:text-white transition-all cursor-pointer  max-md:mt-[100px]">
      {!isNewSectionSelectorOpen && (
        <Add
          className="p-auto"
          onClick={() => {
            setIsNewSectionSelectorOpen(true);
          }}
        />
      )}
      {isNewSectionSelectorOpen && (
        <>
          <ul>
            {newSectionItems.map((sectionType, index) => {
              return (
                <li
                  key={index}
                  className="text-gray-400 hover:text-white"
                  onClick={() => {
                    setBlog((previousBlog) => ({
                      ...previousBlog,
                      article: [
                        ...previousBlog.article,
                        { type: sectionType.value },
                      ],
                    }));
                    setIsNewSectionSelectorOpen(false);
                  }}
                >
                  {sectionType.display}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
}

function BlogArticleSectionActions({
  sectionIndex,
  setBlog,
  blog,
  saveBlogSectionModificationsToState,
}) {
  if (!blog.isPublished)
    return (
      <div className="bottom-0 left-0 z-30 grid opacity-30 hover:opacity-100 ml-[-2.7rem] w-max h-0 text-black absoulute ACTIONS">
        <button
          className="bg-white hover:bg-gray-200 p-2"
          onClick={() => {
            saveBlogSectionModificationsToState();
            setBlog((previousBlog) => {
              const updatedArticles = previousBlog.article.filter(
                (i, fIndex) => fIndex !== sectionIndex
              );

              console.log({
                ...previousBlog,
                article: updatedArticles,
              });

              return {
                ...previousBlog,
                article: updatedArticles,
              };
            });
          }}
        >
          <Delete width={"5px"} />
        </button>

        <button
          className="bg-white hover:bg-gray-200 p-2"
          onClick={() => {
            saveBlogSectionModificationsToState();
            setBlog((previousBlog) => {
              const articles = [...previousBlog.article];
              if (sectionIndex > 1) {
                // Swap current item with the one above it
                [articles[sectionIndex - 1], articles[sectionIndex]] = [
                  articles[sectionIndex],
                  articles[sectionIndex - 1],
                ];
              }
              return { ...previousBlog, article: articles };
            });
          }}
        >
          <ArrowUpward />
        </button>

        <button
          className="bg-white hover:bg-gray-200 p-2"
          onClick={() => {
            saveBlogSectionModificationsToState();
            setBlog((previousBlog) => {
              const articles = [...previousBlog.article];
              if (sectionIndex < articles.length - 1) {
                // Swap current item with the one below it
                [articles[sectionIndex], articles[sectionIndex + 1]] = [
                  articles[sectionIndex + 1],
                  articles[sectionIndex],
                ];
              }
              return { ...previousBlog, article: articles };
            });
          }}
        >
          <ArrowDownward />
        </button>
      </div>
    );
  else return <></>;
}

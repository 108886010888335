import {
  ArrowBack,
  ChevronLeft,
  ChevronRight,
  Close,
} from "@mui/icons-material";
import React, { useState } from "react";
import { blogsData } from "../../lib/blogs.data";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { getAllBlogs, getAllBlogsInShort } from "../../lib/firebase";

export default function BlogsSideBar() {
  const [blogSideBarOpen, setBlogSideBarOpen] = useState(false);
  const [blogsInShort, setBlogsInShort] = useState([]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { blogId } = useParams();
  // useEffect(() => {
  //   const timeOut = setTimeout(() => {
  //     setBlogSideBarOpen(true);
  //   }, 1000);
  //   return () => {
  //     clearTimeout(timeOut);
  //   };
  // }, []);

  useEffect(() => {
    getAllBlogsInShort().then((result) => {
      setBlogsInShort(result);
    });
    if (searchParams.get("image-picker") === "true") setBlogSideBarOpen(false);
  }, [searchParams]);

  return (
    <aside
      className={`top-0 left-0 fixed bg-white h-full transition-all border-r z-20 ${
        blogSideBarOpen ? "w-[240px] " : "w-0"
      }`}
    >
      {blogSideBarOpen && (
        <div
          className="left-full absolute bg-[#00000073] w-screen h-full"
          onClick={() => {
            setBlogSideBarOpen(!blogSideBarOpen);
          }}
        ></div>
      )}

      <button
        className="left-full absolute bg-[#ffffff] p-3 rounded-r-full"
        onClick={() => {
          setBlogSideBarOpen(!blogSideBarOpen);
          
        }}
      >
        {blogSideBarOpen ? <Close /> : <ChevronRight />}
      </button>
      <div
        className={`px-2 pt-[3rem] transition-all overflow-hidden  ${
          blogSideBarOpen ? "" : " w-0 px-0"
        }`}
      >
        <h2 className="font-bold text-xl overflow-hidden">
          {" "}
          <button
            onClick={() => {
              setBlogSideBarOpen(false);
            }}
          >
            <ChevronLeft />
          </button>{" "}
          Blogs
        </h2>
        <ul className="overflow-hidden">
          {blogsInShort.map((blog, index) => {
            return (
              <li
                key={index}
                className={`py-3 border-b text-[14px] text-nowrap cursor-pointer px-2 transition-all overflow-hidden flex ${
                  blogId === blog._id.toString() ? "bg-accent text-white" : ""
                }  ${blogSideBarOpen ? "px-2" : "p-0"}`}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setBlogSideBarOpen(false);
                  window.location.replace(`/admin/blog/${blog._id.toString()}`)
                  // navigate(`/admin/blog/${blog._id.toString()}`);
                }}
              >
                <b className="mr-3">{blog.id}</b>{blog.heading?.substring(0, 20)}... <div className={`w-2 h-2 ml-auto mr-0 mt-[6px] rounded-full animate-pulse inline-block ${blog.isPublished ? 'bg-green-500' : ''}`}></div>
              </li>
            );
          })}
          <li
            className={`py-3 border-b text-[14px] text-nowrap cursor-pointertransition-all overflow-hidden text-center cursor-pointer ${
              blogId === "new" ? "bg-accent text-white" : ""
            } ${blogSideBarOpen ? "px-2" : "p-0"}`}
            onClick={() => {
              navigate(`/admin/blog/new`);
            }}
          >
            <b className="m-auto">New</b>
          </li>
        </ul>
      </div>
    </aside>
  );
}
